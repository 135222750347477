/*------------------------------------------------------------------
[Table of contents]
1. 3rd Part CSS Libraries
2. Global CSS
        2.1 Global Header Logo/Menu CSS	
        2.2 General Section CSS
        2.3 Columns CSS
        2.4 Button CSS
        2.5 Image/Text Slider CSS
3. Home Section CSS
4. Services Section CSS
5. About Section CSS
6. Clients Section CSS
7. News Section CSS
8. Portfolio Section CSS
        8.1 Single Portfolio
9. Pricing Section CSS
10. Team Section CSS
11. Skills Section CSS
12. Milestones Section CSS
13. Blog Page CSS
14. Single Post CSS
        14.1 Tipper CSS
15. Contact Section CSS
16. Footer CSS
17. Responsive CSS
-------------------------------------------------------------------*/

/* ===================================
    1. 3rd Part CSS Libraries
====================================== */

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
fieldset,
form,
label,
legend,
caption,
tfoot,
thead,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

input:focus,
textarea:focus {
  outline: none;
}

/* Mobile first layout SmartMenus Core CSS (it's not recommended editing these rules)
   You need this once per page no matter how many menu trees or different themes you use.
-------------------------------------------------------------------------------------------*/

.sm,
.sm ul,
.sm li {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.sm-rtl,
.sm-rtl ul,
.sm-rtl li {
  direction: rtl;
  text-align: right;
}
.sm > li > h1,
.sm > li > h2,
.sm > li > h3,
.sm > li > h4,
.sm > li > h5,
.sm > li > h6 {
  margin: 0;
  padding: 0;
}
.sm ul {
  display: none;
}
.sm li,
.sm a {
  position: relative;
}
.sm a {
  display: block;
}
.sm a.disabled {
  cursor: not-allowed;
}
.sm:after {
  content: "\00a0";
  display: block;
  height: 0;
  font: 0px/0 serif;
  clear: both;
  visibility: hidden;
  overflow: hidden;
}
.sm,
.sm *,
.sm *:before,
.sm *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.sm-clean {
  background: #eeeeee;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.sm-clean a,
.sm-clean a:hover,
.sm-clean a:focus,
.sm-clean a:active {
  padding: 13px 20px;
  /* make room for the toggle button (sub indicator) */
  padding-right: 58px;
  font-size: 18px;
  font-weight: normal;
  line-height: 17px;
  text-decoration: none;
}
.sm-clean a.disabled {
  color: #bbbbbb;
}
.sm-clean a span.sub-arrow {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: auto;
  right: 4px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  font: bold 16px/34px monospace !important;
  text-align: center;
  text-shadow: none;
  background: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.sm-clean a.highlighted span.sub-arrow:before {
  display: block;
  content: "-";
}
.sm-clean > li:first-child > a,
.sm-clean > li:first-child > :not(ul) a {
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  -o-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}
.sm-clean > li:last-child > a,
.sm-clean > li:last-child > *:not(ul) a,
.sm-clean > li:last-child > ul,
.sm-clean > li:last-child > ul > li:last-child > a,
.sm-clean > li:last-child > ul > li:last-child > *:not(ul) a,
.sm-clean > li:last-child > ul > li:last-child > ul,
.sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > a,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > *:not(ul)
  a,
.sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > a,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > *:not(ul)
  a,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > a,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > *:not(ul)
  a,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul {
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  -ms-border-radius: 0 0 5px 5px;
  -o-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
}
.sm-clean > li:last-child > a.highlighted,
.sm-clean > li:last-child > *:not(ul) a.highlighted,
.sm-clean > li:last-child > ul > li:last-child > a.highlighted,
.sm-clean > li:last-child > ul > li:last-child > *:not(ul) a.highlighted,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > a.highlighted,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > *:not(ul)
  a.highlighted,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > a.highlighted,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > *:not(ul)
  a.highlighted,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > a.highlighted,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > *:not(ul)
  a.highlighted {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
.sm-clean li {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.sm-clean > li:first-child {
  border-top: 0;
}
.sm-clean ul {
  background: transparent;
}
.sm-clean ul a,
.sm-clean ul a:hover,
.sm-clean ul a:focus,
.sm-clean ul a:active {
  font-size: 16px;
}
.sm-clean ul ul a,
.sm-clean ul ul a:hover,
.sm-clean ul ul a:focus,
.sm-clean ul ul a:active {
  border-left: 16px solid transparent;
}
.sm-clean ul ul ul a,
.sm-clean ul ul ul a:hover,
.sm-clean ul ul ul a:focus,
.sm-clean ul ul ul a:active {
  border-left: 24px solid transparent;
}
.sm-clean ul ul ul ul a,
.sm-clean ul ul ul ul a:hover,
.sm-clean ul ul ul ul a:focus,
.sm-clean ul ul ul ul a:active {
  border-left: 32px solid transparent;
}
.sm-clean ul ul ul ul ul a,
.sm-clean ul ul ul ul ul a:hover,
.sm-clean ul ul ul ul ul a:focus,
.sm-clean ul ul ul ul ul a:active {
  border-left: 40px solid transparent;
}

@media (min-width: 925px) {
  /* Switch to desktop layout
    -----------------------------------------------
       These transform the menu tree from
       collapsible to desktop (navbar + dropdowns)
    -----------------------------------------------*/
  /* start... (it's not recommended editing these rules) */
  .sm-clean ul {
    position: absolute;
  }

  .sm-clean li {
    float: left;
  }

  .sm-clean.sm-rtl li {
    float: right;
  }

  .sm-clean ul li,
  .sm-clean.sm-rtl ul li,
  .sm-clean.sm-vertical li {
    float: none;
  }

  .sm-clean a {
    white-space: nowrap;
  }

  .sm-clean ul a,
  .sm-clean.sm-vertical a {
    white-space: normal;
  }

  .sm-clean .sm-nowrap > li > a,
  .sm-clean .sm-nowrap > li > :not(ul) a {
    white-space: nowrap;
  }

  /* ...end */
  .sm-clean {
    padding: 0 10px;
    background: #eeeeee;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
  }
  .sm-clean a,
  .sm-clean a:hover,
  .sm-clean a:focus,
  .sm-clean a:active,
  .sm-clean a.highlighted {
    padding: 12px 12px;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
    border-radius: 0 !important;
  }

  .sm-clean a.has-submenu {
    padding-right: 24px;
  }
  .sm-clean a span.sub-arrow {
    top: 0;
    margin-top: 3px;
    right: -3px;
    width: 34px;
    height: 34px;
    background: transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
  }
  .sm-clean a.highlighted span.sub-arrow:before {
    display: none;
  }
  .sm-clean li {
    border-top: 0;
  }
  .sm-clean > li > ul:after {
    top: -16px;
    left: 31px;
    border-width: 8px;
    border-color: transparent transparent white transparent;
  }
  .sm-clean ul {
    padding: 5px 0;
    background: white;
  }
  .sm-clean ul a,
  .sm-clean ul a:hover,
  .sm-clean ul a:focus,
  .sm-clean ul a:active,
  .sm-clean ul a.highlighted {
    border: 0 !important;
    padding: 12px;
  }
  .sm-clean ul a.disabled {
    background: white;
    color: #cccccc;
  }
  .sm-clean ul a.has-submenu {
    padding-right: 20px;
  }
  .sm-clean ul a span.sub-arrow {
    right: 28px;
    top: 0;
    margin-top: 3px;
    border: 0;
  }
  .sm-clean span.scroll-up,
  .sm-clean span.scroll-down {
    position: absolute;
    display: none;
    visibility: hidden;
    overflow: hidden;
    background: white;
    height: 20px;
  }
  .sm-clean span.scroll-up:hover,
  .sm-clean span.scroll-down:hover {
    background: #eeeeee;
  }
  .sm-clean span.scroll-up:hover span.scroll-up-arrow,
  .sm-clean span.scroll-up:hover span.scroll-down-arrow {
    border-color: transparent transparent #d23600 transparent;
  }
  .sm-clean span.scroll-down:hover span.scroll-down-arrow {
    border-color: #d23600 transparent transparent transparent;
  }
  .sm-clean span.scroll-up-arrow,
  .sm-clean span.scroll-down-arrow {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -6px;
    width: 0;
    height: 0;
    overflow: hidden;
    border-width: 6px;
    border-style: dashed dashed solid dashed;
    border-color: transparent transparent #555555 transparent;
  }
  .sm-clean span.scroll-down-arrow {
    top: 8px;
    border-style: solid dashed dashed dashed;
    border-color: #555555 transparent transparent transparent;
  }
  .sm-clean.sm-rtl a.has-submenu {
    padding-right: 12px;
    padding-left: 24px;
  }
  .sm-clean.sm-rtl a span.sub-arrow {
    right: auto;
    left: 12px;
  }
  .sm-clean.sm-rtl.sm-vertical a.has-submenu {
    padding: 10px 20px;
  }
  .sm-clean.sm-rtl.sm-vertical a span.sub-arrow {
    right: auto;
    left: 8px;
    border-style: dashed solid dashed dashed;
    border-color: transparent #555555 transparent transparent;
  }
  .sm-clean.sm-rtl > li > ul:before {
    left: auto;
    right: 30px;
  }
  .sm-clean.sm-rtl > li > ul:after {
    left: auto;
    right: 31px;
  }
  .sm-clean.sm-rtl ul a.has-submenu {
    padding: 10px 20px !important;
  }
  .sm-clean.sm-rtl ul a span.sub-arrow {
    right: auto;
    left: 8px;
    border-style: dashed solid dashed dashed;
    border-color: transparent #555555 transparent transparent;
  }
  .sm-clean.sm-vertical {
    padding: 10px 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
  }
  .sm-clean.sm-vertical a {
    padding: 10px 20px;
  }
  .sm-clean.sm-vertical a:hover,
  .sm-clean.sm-vertical a:focus,
  .sm-clean.sm-vertical a:active,
  .sm-clean.sm-vertical a.highlighted {
    background: white;
  }
  .sm-clean.sm-vertical a.disabled {
    background: #eeeeee;
  }
  .sm-clean.sm-vertical a span.sub-arrow {
    right: 8px;
    top: 50%;
    margin-top: -5px;
    border-width: 5px;
    border-style: dashed dashed dashed solid;
    border-color: transparent transparent transparent #555555;
  }
  .sm-clean.sm-vertical > li > ul:before,
  .sm-clean.sm-vertical > li > ul:after {
    display: none;
  }
  .sm-clean.sm-vertical ul a {
    padding: 10px 20px;
  }
  .sm-clean.sm-vertical ul a:hover,
  .sm-clean.sm-vertical ul a:focus,
  .sm-clean.sm-vertical ul a:active,
  .sm-clean.sm-vertical ul a.highlighted {
    background: #eeeeee;
  }
  .sm-clean.sm-vertical ul a.disabled {
    background: white;
  }
}

/*------------------------------------------------------------------
[Table of contents]
1. Common CSS
-------------------------------------------------------------------*/

/* ===================================
    1. Common CSS
====================================== */

.block {
  display: block;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.line-height {
  line-height: 0;
}

.clear-right {
  clear: right;
}

.clear-left {
  clear: left;
}

.center-relative {
  margin-left: auto;
  margin-right: auto;
}

.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.center-text,
.center {
  text-align: center;
}

.text-left,
.left {
  text-align: left;
}

.text-right,
.right {
  text-align: right;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.margin-0 {
  margin: 0 !important;
  line-height: 0;
}

.top-0 {
  margin-top: 0 !important;
}

.top-5 {
  margin-top: 5px !important;
}

.top-10 {
  margin-top: 10px;
}

.top-15 {
  margin-top: 15px;
}

.top-20 {
  margin-top: 20px;
}

.top-25 {
  margin-top: 25px;
}

.top-30 {
  margin-top: 30px;
}

.top-50 {
  margin-top: 50px;
}

.top-60 {
  margin-top: 60px;
}

.top-70 {
  margin-top: 70px;
}

.top-75 {
  margin-top: 75px;
}

.top-80 {
  margin-top: 80px;
}

.top-100 {
  margin-top: 100px;
}

.top-105 {
  margin-top: 105px;
}

.top-120 {
  margin-top: 120px;
}

.top-150 {
  margin-top: 150px;
}

.top-200 {
  margin-top: 200px;
}

.bottom-0 {
  margin-bottom: 0 !important;
}

.bottom-10 {
  margin-bottom: 10px !important;
}

.bottom-20 {
  margin-bottom: 20px !important;
}

.bottom-25 {
  margin-bottom: 25px !important;
}

.bottom-30 {
  margin-bottom: 30px !important;
}

.bottom-50 {
  margin-bottom: 50px !important;
}

.bottom-100 {
  margin-bottom: 100px !important;
}

.bottom-150 {
  margin-bottom: 150px !important;
}

.bottom-200 {
  margin-bottom: 200px !important;
}

blockquote {
  font-weight: 700;
  font-style: italic;
  margin-bottom: 50px;
  font-size: 21px;
  line-height: 34px;
  position: relative;
  color: #000;
  margin-top: 95px;
}

blockquote cite {
  margin-top: 20px;
  display: block;
  font-weight: 400;
}

blockquote:before {
  content: "";
  background-image: url("./images/quotes_image.png");
  background-size: 50px;
  width: 50px;
  height: 50px;
  display: block;
  background-repeat: no-repeat;
  margin-bottom: 15px;
  position: absolute;
  top: -50px;
}

iframe {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 20px 0;
}

h1 {
  font-size: 38px;
  line-height: 38px;
}

h2 {
  font-size: 34px;
  line-height: 34px;
}

h3 {
  font-size: 32px;
  line-height: 32px;
}

h4 {
  font-size: 30px;
}

h5 {
  font-size: 28px;
}

h6 {
  font-size: 26px;
}

em {
  font-style: italic;
}

ol {
  list-style-type: decimal;
}

pre {
  word-wrap: break-word;
  white-space: pre-wrap;
}

table,
th,
td {
  border: 1px solid black;
  padding: 10px;
  vertical-align: middle;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

/* ===================================
    2. Global CSS
====================================== */

body {
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  line-height: 35px;
  font-weight: 400;
  color: #b3b3b3;
  background-color: #ffffff;
  overflow-x: hidden;
}

body a {
  text-decoration: none;
  /* color: #47ea4e;
   */
  color: #2b7a0b;
  transition: color 0.3s ease;
}

body a:hover {
  color: #2b7a0b;
}

body p,
body pre {
  margin-bottom: 13px;
}

.site-wrapper img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.site-wrapper img.home-section-image {
  max-width: 50%;
  max-height: 50%;
  width: auto;
  height: auto;
}

.doc-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: #000;
}

.doc-loader img {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-1170 {
  width: 1170px;
}

.content-960 {
  width: 960px;
}

.content-570 {
  width: 570px;
}

.pagination-holder {
  margin: 60px 0;
}

.section-wrapper {
  position: relative;
  padding: 200px 0 115px 0;
}

/* ===================================
    2.1 Global Header Logo/Menu CSS
====================================== */

.header-holder {
  z-index: 99 !important;
  background-color: #060606;
  width: 100% !important;
  top: 0;
  position: fixed;
  -webkit-transform: translateZ(0);
}

.sticky-wrapper {
  height: 77px !important;
}

.menu-wrapper {
  width: 98%;
  max-width: 1450px;
  min-height: 77px;
}

.header-logo {
  line-height: 0;
  font-size: 0;
  float: left;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}

.header-logo img {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100px;
}

.menu-holder {
  float: right;
  max-width: 95%;
}

.toggle-holder {
  display: none;
  right: 10px;
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#toggle:hover {
  cursor: pointer;
}

#toggle div {
  height: 3px;
  margin-bottom: 6px;
  background-color: #fff;
  width: 22px;
}

#toggle .second-menu-line {
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}

#toggle:hover .second-menu-line {
  -webkit-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
}

#toggle.on .second-menu-line {
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}

#toggle .third-menu-line {
  margin-bottom: 5px;
}

#header-main-menu {
  display: inline-block;
}

.sm-clean .has-submenu {
  padding-right: 35px !important;
}

.sm-clean li a.menu-item-link {
  cursor: pointer;
  color: #ffffff;
}

.sm-clean a span.sub-arrow {
  right: 3px;
}

.sm-clean a.highlighted {
  padding: 15px;
}

.sm-clean a,
.sm-clean a:hover,
.sm-clean a:focus,
.sm-clean a:active {
  font-size: 14px;
  font-weight: 400;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 15px;
  letter-spacing: 0.5px;
  color: #fff;
}

.sm-clean ul a,
.sm-clean ul a:hover,
.sm-clean ul a:focus,
.sm-clean ul a:active {
  font-size: 13px;
  line-height: 15px;
  padding: 10px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.sm-clean {
  background-color: transparent;
  padding: 15px 0;
}

body .sm-clean a:hover,
body .main-menu.sm-clean .sub-menu li a:hover,
body .sm-clean li.active a,
body .sm-clean li.current-page-ancestor > a,
body .sm-clean li.current_page_ancestor > a,
body .sm-clean li.current_page_item > a {
  color: #2b7a0b;
}

.sm-clean li {
  margin-right: 10px;
}

.sm-clean li:last-child {
  margin-right: 0;
}

.sm-clean ul {
  background-color: #000;
}

.sm-clean a.has-submenu {
  padding-right: 24px;
}

.sub-menu a,
.children a {
  padding-left: 15px !important;
}

.sub-menu .sub-menu,
.children .children {
  padding-top: 8px;
}

.sub-menu .sub-menu a,
.children .children a,
.sub-menu .sub-menu a:hover,
.children .children a:hover {
  padding-top: 10px !important;
}

@media (min-width: 925px) {
  .sm-clean ul a span.sub-arrow {
    margin-top: 0;
  }
  .sm-clean ul {
    padding: 8px 0;
  }
}

@media (max-width: 925px) {
  .sm-clean ul {
    background-color: #fff;
  }
}

.sm-clean > li:last-child > a,
.sm-clean > li:last-child > *:not(ul) a,
.sm-clean > li:last-child > ul,
.sm-clean > li:last-child > ul > li:last-child > a,
.sm-clean > li:last-child > ul > li:last-child > *:not(ul) a,
.sm-clean > li:last-child > ul > li:last-child > ul,
.sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > a,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > *:not(ul)
  a,
.sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > a,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > *:not(ul)
  a,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > a,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > *:not(ul)
  a,
.sm-clean
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul
  > li:last-child
  > ul {
  border-radius: 5px;
}

/* ===================================
    2.2 General Section CSS
====================================== */

.section {
  position: relative;
}

.page-title-holder {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #2b7a0b;
  width: 285px;
  display: inline-block;
  z-index: 98;
  height: 130px;
}

.page-title-holder:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 130px 60px 0 0;
  border-color: #2b7a0b transparent transparent transparent;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.page-title-holder-left {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #2b7a0b;
  width: 285px;
  display: inline-block;
  z-index: 98;
  height: 130px;
}

.page-title-holder-left:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 130px 60px;
  border-color: transparent transparent #2b7a0b transparent;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.section h3.entry-title {
  font-size: 16px;
  line-height: 149%;
  font-weight: 400;
  word-break: break-word;
  text-align: center;
  color: #fff;
  letter-spacing: 2px;
  margin-top: 32px;
}

.page .page-content {
  background-color: #fff;
  padding: 75px;
}

.no-padding .section-wrapper {
  padding: 0 !important;
  width: 100%;
  max-width: 100%;
}

[data-jarallax-element] {
  z-index: 30 !important;
}

.medium-text {
  color: #000;
  font-size: 60px;
  line-height: 120%;
  font-weight: 700;
  padding-bottom: 35px;
}

.title-description-up {
  font-size: 16px;
  margin-bottom: 0;
  letter-spacing: 2px;
}

.info-text {
  font-size: 22px;
  line-height: 190%;
  color: #000;
}

.info-code-title {
  font-size: 16px;
  color: #000;
  letter-spacing: 2px;
  margin-bottom: 0;
  line-height: 120%;
}

.info-code-content,
.info-code-content a {
  color: #b3b3b3;
  margin-bottom: 30px;
}

.tp-bullet-title {
  display: none !important;
}

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  transition-duration: 0s;
}

.social {
  display: inline-block;
  font-size: 35px;
  margin-right: 20px;
}

body .social a {
  color: #000 !important;
  transition: all 0.3s ease;
}

body .social a:hover {
  opacity: 0.5;
}

/* ===================================
    2.3 Columns CSS
====================================== */

.one_half,
.one_third,
.two_third,
.three_fourth,
.one_fourth {
  margin-right: 8%;
  float: left;
  position: relative;
  margin-bottom: 30px;
}

.last {
  margin-right: 0 !important;
  clear: right;
}

.one {
  display: block;
  clear: both;
  margin-bottom: 30px;
}

.one_half {
  width: 46%;
}

.one_third {
  width: 28%;
}

.two_third {
  width: 64%;
}

.one_fourth {
  width: 19%;
}

.three_fourth {
  width: 73%;
}

.one_half.margin-0 {
  width: 50%;
}

.one_third.margin-0 {
  width: calc(100% / 3);
}

.two_third.margin-0 {
  width: calc(100% / 3 * 2);
}

.one_fourth.margin-0 {
  width: 25%;
}

.three_fourth.margin-0 {
  width: 75%;
}

/* ===================================
    2.4 Button CSS
====================================== */

body a.button {
  display: inline-block;
  color: #fff;
  background-color: #2b7a0b;
  text-align: center;
  padding: 10px 40px;
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none;
  transition: all 0.2s linear;
  margin-bottom: 17px;
  border: 2px solid #2b7a0b;
  border-radius: 50px;
  font-size: 14px;
  letter-spacing: 2px;
  box-sizing: border-box;
}

a.button:hover {
  background-color: transparent;
  color: #2b7a0b;
}

a.button-dot {
  color: #fff !important;
  font-size: 16px;
  position: relative;
}

a.button-dot:hover {
  color: #fff;
}

a.button-dot span {
  transition: 0.3s;
  display: inline-block;
  margin-left: 50px;
  letter-spacing: 2px;
}

a.button-dot:hover span {
  transform: translateX(-8px);
}

a.button-dot:before {
  content: "";
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  background: transparent;
  border-radius: 90%;
  top: -7px;
  position: absolute;
  transition: 0.3s;
}

a.button-dot:hover:before {
  width: calc(100% + 30px);
  height: 51px;
  border-radius: 50px;
  top: -18px;
}

body a.button.special {
  background-color: #c6a627;
  border: 2px solid #c6a627;
}

body a.button.special:hover {
  background-color: transparent;
  color: #c6a627;
}
/* ===================================
    2.5 Image/Text Slider CSS
====================================== */

.image-slider-wrapper {
  margin-bottom: 20px;
}

.single-post-header-content .image-slider-wrapper {
  margin-bottom: 0;
}

.owl-carousel .owl-stage-outer {
  height: auto !important;
}

.owl-theme .owl-dots {
  padding-top: 25px;
  line-height: 0;
}

.owl-theme .owl-dots .owl-dot span {
  margin: 0;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all 0.2s ease;
  width: 17px;
  height: 17px;
  border-radius: 90%;
}

.owl-theme .owl-dots .owl-dot {
  border-radius: 90%;
  transition: all 0.3s ease;
  border: 2px solid #000;
  margin: 3px;
}

.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #fff;
}

.owl-theme .owl-dots .owl-dot.active {
  background-color: #000;
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: transparent;
}

.text-slider-wrapper {
  font-size: 24px;
  line-height: 35px;
  background-color: #000;
  padding: 130px;
  padding-bottom: 20px;
}

.text-slider-header-quotes {
  margin-bottom: 60px;
  background-image: url(images/quotes_image.png);
  background-repeat: no-repeat;
  width: 87px;
  height: 74px;
  background-size: 87px 74px;
}

.text-slider-wrapper .text-slide {
  text-align: left;
  font-size: 28px;
  line-height: 170%;
  overflow: hidden;
}

.text-slider-wrapper .text-slide:after {
  content: "";
  display: table;
  clear: both;
}

.text-slider-wrapper .text-slide-name {
  font-size: 18px;
  line-height: 100%;
  margin: 0 12px 0 0;
  display: inline-block;
  vertical-align: middle;
}

.text-slider-wrapper .text-slide-position {
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 4px;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.text-slider-wrapper img.text-slide-img {
  width: 100px !important;
  height: auto;
  border-radius: 90%;
  display: inline-block;
  margin-right: 25px;
  vertical-align: middle;
}

.text-slide-content {
  margin-bottom: 60px;
}

.text-slider-wrapper .owl-nav {
  position: absolute;
  bottom: 10px;
  right: 0;
}

.text-slider-wrapper .owl-theme .owl-prev {
  background-image: url("images/nav_left.png") !important;
  background-color: transparent !important;
  font-size: 0;
  width: 30px;
  padding: 0;
  height: 30px;
  background-repeat: no-repeat !important;
  background-size: 30px !important;
  transition: 0.3s;
  opacity: 0.7;
  margin-right: 10px;
}

.text-slider-wrapper .owl-theme .owl-prev:hover {
  opacity: 1;
}

.text-slider-wrapper .owl-theme .owl-next {
  background-image: url("images/nav_right.png") !important;
  background-color: transparent !important;
  font-size: 0;
  width: 30px;
  padding: 0;
  height: 30px;
  background-repeat: no-repeat !important;
  background-size: 30px !important;
  transition: 0.3s;
  opacity: 0.7;
}

.text-slider-wrapper .owl-theme .owl-next:hover {
  opacity: 1;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

/* ===================================
    3. Home Section CSS
====================================== */

#home {
  background-image: url(images/hero4.jpg);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 77px);
  display: flex;
}

#filler-div {
  height: 400px;
}

#home-overlay {
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
}

#home .section-wrapper {
  margin: auto;
  padding: 0;
}

h1.big-text {
  color: #fff;
  font-size: 80px;
  line-height: 120%;
  margin-bottom: 40px;
}

/* ===================================
    4. Services Section CSS
====================================== */

#services {
  background-image: url(images/on_black_left.png);
  background-color: #000000;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: auto;
}

.service-holder {
  margin: 25px 0;
}

.service-holder:after {
  content: "";
  display: block;
  clear: both;
}

.service-holder.values {
  text-align: center;
}

.service-txt h4 {
  font-size: 46px;
  color: #fff;
  font-weight: 700;
  line-height: 110%;
}

.service-txt-info {
  min-height: 240px;
}

.service-num {
  font-size: 264px;
  font-weight: 700;
  color: #000000;
  text-shadow: -1px 0 #b3b3b3, 0 1px #b3b3b3, 1px 0 #b3b3b3, 0 -1px #b3b3b3;
  line-height: 100%;
  height: 165px;
  overflow: hidden;
  margin-bottom: -10px;
  -webkit-transform: translateZ(0);
}

/* ===================================
    5. About Section CSS
====================================== */

#about {
  background-image: url(images/on_white_right.png);
  background-color: #ffffff;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: auto;
}

/* ===================================
    6. Clients Section CSS
====================================== */

#clients {
  background-image: url(images/white_wall.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* ===================================
    7. News Section CSS
====================================== */

#news {
  background-image: url(images/on_white_left.png);
  background-color: #ffffff;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto;
}

.blog-holder-scode {
  margin-top: 0;
  width: 1370px;
  transform: translateX(-100px);
  text-align: center;
}

.blog-holder-scode article:nth-of-type(3n) {
  margin-right: 0;
}

.blog-item-holder-scode {
  margin: 30px 0;
  width: 40%;
  display: inline-block;
  /* margin-right: 8%; */
  vertical-align: text-top;
  text-align: left;
}

.blog-item-holder-scode .excerpt {
  margin-top: 20px;
}

.blog-item-holder-scode h4 {
  padding: 0;
  margin: 35px 0;
}

.blog-item-holder-scode h4 a {
  color: #000 !important;
  font-size: 34px;
  line-height: 110%;
  font-weight: 700;
  transition: 0.3s;
  letter-spacing: -1px;
}

.blog-item-holder-scode h4 a:hover {
  opacity: 0.8;
}

.blog-item-holder-scode .post-thumbnail {
  overflow: hidden;
}

img.latest-posts-background-featured-image-holder {
  height: 600px;
  object-fit: cover;
  width: auto;
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; */
  transition: 0.3s;
}

img.latest-posts-background-featured-image-holder:hover {
  opacity: 0.8;
  transform: scale(1.1);
}

/* ===================================
    8. Portfolio Section CSS
====================================== */

.grid {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  max-width: 100%;
  font-size: 0;
  line-height: 0;
}

.grid-sizer {
  width: 25%;
}

.grid-item {
  position: relative;
  float: left;
  font-size: 0;
  line-height: 0;
  width: 100%;
  overflow: hidden;
}

.grid-item.p_one_fourth {
  width: 25%;
}

.grid-item.p_one_half {
  width: 50%;
}

.grid-item.p_three_fourth {
  width: 75%;
}

.grid-item.p_one {
  width: 100%;
}

.grid-item img {
  width: 100% !important;
  height: auto;
  display: block;
  overflow: hidden;
  object-fit: cover;
}

.grid-item .entry-holder {
  text-align: center;
  background-color: #fff;
  padding: 50px 20px;
}

.grid-item a.item-link:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.portfolio-text-holder {
  color: #fff;
  position: absolute;
  bottom: 50px;
  left: 50px;
  display: inline-block;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease;
}

.portfolio-title {
  font-size: 30px;
  line-height: 170%;
  margin-bottom: 0;
  font-weight: 700;
  color: #b3b3b3;
}

.portfolio-desc {
  font-size: 14px;
  line-height: 22px;
  color: #b3b3b3;
  letter-spacing: 2px;
  margin-bottom: 0;
}

.grid-item a.item-link:hover:after,
.grid-item a.item-link:hover .portfolio-text-holder {
  opacity: 1;
}

.portfolio-content h1 {
  font-size: 44px;
  color: #000;
  line-height: 130%;
}

.portfolio-load-content-holder {
  opacity: 0;
  transform: translateY(70px);
  transition: all 0.5s ease;
  width: 1170px;
  max-width: 75%;
  margin: 0 auto;
}

.portfolio-load-content-holder.show {
  transform: translateY(0);
  opacity: 1;
}

.portfolio-load-content-holder.show.viceversa {
  opacity: 0;
  transform: translateY(70px);
}

.section.no-padding .portfolio-content-wrapper {
  padding: 195px 0 75px 0;
}

.portfolio-content-wrapper {
  display: none;
}

.portfolio-content-wrapper.show {
  display: block;
}

#portfolio {
  padding-top: 7rem;
  padding-bottom: 3rem;
  background-color: #000;
  color: white;
}

#portfolio h2,
#portfolio p {
  text-align: center;
  color: white;
}

#portfolio-grid {
  transition: all 0.5s ease;
  opacity: 1;
  transform: translateY(0px);
}

#portfolio-grid.hide {
  opacity: 0;
  transform: translateY(70px);
}

.close-icon {
  background-image: url(images/close-left-arrow.png);
  background-repeat: no-repeat;
  width: 70px;
  height: 70px;
  display: inline-block;
  margin-bottom: 20px;
  background-size: 40px;
  background-position: 50%;
  transition: all 0.3s ease;
  cursor: pointer;
  position: absolute;
  left: -100px;
  top: 195px;
}

.close-icon:hover {
  transform: translateX(-5px);
}

/* ===================================
    8.1 Single Portfolio
====================================== */

.portfolio-item-wrapper {
  margin-top: 100px;
  margin-bottom: 50px;
}

/* ===================================
    9. Pricing Section CSS
====================================== */

#pricing {
  background-image: url(images/on_white_right.png);
  background-color: #ffffff;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto;
}

.pricing-table {
  padding-bottom: 20px;
  background-color: #ffffff;
  margin-top: 30px;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #000;
  position: relative;
  text-align: left;
}

.pricing-wrapper {
  padding: 0 15%;
}

.pricing-table-title {
  color: #000;
  font-size: 21px;
  font-weight: 500;
  padding: 40px 0;
  margin-bottom: 25px;
}

.pricing-num {
  font-size: 200px;
  font-weight: 700;
  color: #ffffff;
  text-shadow: -1px 0 #b3b3b3, 0 1px #b3b3b3, 1px 0 #b3b3b3, 0 -1px #b3b3b3;
  line-height: 100%;
  margin-bottom: -10px;
  position: absolute;
  top: -50px;
  right: -9px;
  z-index: 1;
}

.pricing-num:before {
  content: "";
  width: 150px;
  background-color: white;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: -1;
}

.pricing-table-price {
  font-size: 100px;
  line-height: 120%;
  color: #2b7a0b;
  font-weight: 700;
}

.pricing-table-desc {
  font-size: 16px;
  letter-spacing: 2px;
}

.pricing-table ul {
  padding: 0;
  list-style: none;
  margin-top: 25px;
}

.pricing-table ul li {
  padding: 10px 0;
  color: #000;
}

.pricing-list span.fa {
  color: #2b7a0b;
  margin-right: 25px;
}

.pricing-list.included-no,
.pricing-list.included-no span.fa {
  color: gray;
}

.pricing-list.included-no span.fa:before {
  content: "\f00d";
}

.pricing-table-content-holder {
  margin-bottom: 40px;
}

.pricing-button {
  text-align: center;
}

/* ===================================
    10. Team Section CSS
====================================== */

#team {
  background-image: url(images/on_black_left.png);
  background-color: #000000;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto;
}

.member {
  text-align: center;
  margin: 70px 0;
}

.member img {
  display: inline-block;
  vertical-align: middle;
  max-width: 700px;
}

.member-content {
  margin-bottom: 35px;
}

.member-info {
  width: 380px;
  background-color: #fff;
  padding: 65px 110px;
  display: inline-block;
  vertical-align: middle;
  margin-left: -130px;
  text-align: left;
  position: relative;
  z-index: 31;
}

.member-right .member-info {
  margin-left: auto;
  margin-right: -130px;
}

.member-postition {
  margin: 0;
  font-size: 16px;
  letter-spacing: 2px;
}

.member-name {
  font-size: 48px;
  font-weight: 700;
  color: #000;
  line-height: 150%;
  margin-bottom: 35px;
  margin-top: -5px;
  padding: 0;
}

.member-social-holder {
  background: #2b7a0b;
  padding: 25px 50px;
  position: absolute;
  bottom: -35px;
  left: -45px;
}

.member-right .member-social-holder {
  left: auto;
  right: -45px;
}

.member-social-holder .social a {
  color: #fff !important;
}

/* ===================================
    11. Skills Section CSS
====================================== */

.skills-holder {
  position: relative;
  margin: 40px 0;
}

.skill-holder {
  margin-bottom: 30px;
}

.skill-holder:after {
  clear: both;
  display: block;
  content: "";
}

.skill-percent {
  font-size: 48px;
  line-height: 100%;
  font-weight: 100;
  width: 100px;
  display: inline-block;
  vertical-align: text-top;
}

.skill {
  width: 100%;
  height: 3px;
  background-color: rgba(202, 202, 202, 0.3);
  margin-bottom: 30px;
}

.skill-text {
  display: inline-block;
  width: calc(100% - 145px);
  margin-right: 0;
  float: right;
  vertical-align: text-top;
}

.skill-fill {
  width: 100%;
  height: 10px;
  background-color: #000;
}

.skill-text span {
  font-size: 22px;
  line-height: 35px;
}

/* ===================================
    12. Milestones Section CSS
====================================== */

#millstones {
  background-image: url(images/on_black_left.png);
  background-color: #000000;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: auto;
}

ul.milestones-holder {
  padding: 0;
  list-style: none;
  margin: 0;
}

li.milestone {
  width: 45%;
  display: inline-block;
  margin: 30px 9% 30px 0;
}

li.milestone:nth-child(2n) {
  margin-right: 0;
}

li.milestone > div {
  display: inline-block;
  vertical-align: text-top;
}

li.milestone h5 {
  color: #ffffff;
  max-width: 240px;
  padding-top: 0;
  line-height: 115%;
}

li.milestone .milestone-info-left {
  margin-right: 35px;
  text-align: right;
  width: 30%;
}

li.milestone .milestone-info-right {
  width: 62%;
}

p.milestone-num {
  font-size: 80px;
  line-height: 100%;
  font-weight: 700;
  position: relative;
  margin-bottom: 0;
  color: #000000;
  text-shadow: -1px 0 #b3b3b3, 0 1px #b3b3b3, 1px 0 #b3b3b3, 0 -1px #b3b3b3;
}

h6.logo-outline {
  font-size: 30px;
  line-height: 100%;
  font-weight: 700;
  position: relative;
  margin-bottom: 0;
  color: #000000;
  text-shadow: -1px 0 #ffffff, 0 1px #ffffff, 1px 0 #ffffff, 0 -1px #ffffff;
}

/* ===================================
    13. Blog Page CSS
====================================== */

body.blog {
  background-color: #fff;
}

.blog .site-content {
  background-color: #fff;
}

.blog h1.entry-title {
  text-align: center;
  display: table;
  margin: 50px auto;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  color: #fff;
  background-color: #f1576b;
  padding: 15px 25px;
  border-radius: 50px;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
}

.blog-holder {
  width: 1170px;
  max-width: 95%;
}

.blog-item-holder {
  margin: 120px 0;
  text-align: center;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.blog-item-wrapper {
  display: inline-block;
}

.blog-item-holder .excerpt {
  width: 700px;
  margin: 0 auto;
  max-width: 95%;
  margin-bottom: 30px;
  margin-top: 25px;
}

.blog-item-holder.has-post-thumbnail {
  width: 1170px;
  max-width: 100%;
}

.blog-item-holder.has-post-thumbnail .excerpt {
  width: 100%;
  max-width: 100%;
  margin-bottom: 60px;
}

.blog-item-holder .post-thumbnail {
  max-width: 590px;
  overflow: hidden;
  line-height: 0;
}

.post-thumbnail-image {
  transition: 0.3s;
}

.post-thumbnail-image:hover {
  opacity: 0.8;
}

.blog-item-holder.has-post-thumbnail:nth-of-type(2n + 1) .post-thumbnail {
  float: left;
  text-align: right;
}

.blog-item-holder.has-post-thumbnail:nth-of-type(2n + 1) .entry-holder {
  float: left;
  position: relative;
  z-index: 31;
}

.blog-item-holder.has-post-thumbnail:nth-of-type(2n) .post-thumbnail {
  float: right;
  text-align: left;
  margin-right: 0;
}

.blog-item-holder.has-post-thumbnail:nth-of-type(2n) .entry-holder {
  float: right;
  position: relative;
  z-index: 31;
}

.blog-item-holder.has-post-thumbnail .entry-holder {
  width: 430px;
  text-align: left;
  padding: 0 75px;
  background: #fff;
}

.blog-item-holder.has-post-thumbnail.is-smaller .entry-holder {
  margin-top: 40px;
}

.blog-item-holder h2.entry-title {
  display: block;
  font-weight: 700;
  font-size: 42px;
  line-height: 46px;
  padding-top: 0;
  word-break: break-word;
  letter-spacing: -2px;
}

.blog-item-holder h2.entry-title a {
  color: #000 !important;
  transition: 0.2s;
}

.blog-item-holder h2.entry-title a:hover {
  opacity: 0.8;
}

.blog-item-holder .cat-links ul {
  list-style: none;
  padding: 0;
  display: inline-block;
}

.blog-item-holder .cat-links ul li {
  display: inline-block;
}

.blog-item-holder .cat-links ul li a:after {
  content: ",";
  padding-right: 5px;
}

.blog-item-holder .cat-links ul li:last-child a:after {
  display: none;
}

.blog-item-holder .cat-links ul a {
  color: #000000 !important;
  transition: 0.2s;
}

.blog-item-holder .cat-links ul a:hover {
  opacity: 0.8;
}

.blog-item-holder .entry-date.published,
.blog-item-holder .cat-links {
  display: inline-block;
  font-size: 12px;
  margin-top: -10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.blog-item-holder .cat-links:after {
  content: "\2022";
  display: inline-block;
  margin: 0 7px 0 10px;
}

.blog-item-holder a.item-button {
  display: inline-block;
  color: #fff;
  background-color: #2b7a0b;
  text-align: left;
  padding: 10px 40px;
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none;
  transition: all 0.2s linear;
  margin-bottom: 17px;
  border: 2px solid #2b7a0b;
  border-radius: 50px;
  font-size: 14px;
  letter-spacing: 2px;
  box-sizing: border-box;
  margin-bottom: 0;
}

.blog-item-holder a.item-button:hover {
  background-color: #fff;
  color: #2b7a0b;
}

/* ===================================
    14. Single Post CSS
====================================== */

.single .site-content {
  background-color: #fff;
}

.single-post-header-content {
  margin-bottom: 55px;
}

.single .post-info-wrapper {
  margin-bottom: 30px;
  font-size: 15px;
  color: #939393;
  width: 150px;
  margin-right: 200px;
  float: left;
  padding-top: 7px;
}

.single .post-info-wrapper a {
  color: #939393 !important;
}

.single .sticky-spacer {
  position: static !important;
  margin-bottom: 0 !important;
  max-width: 150px;
}

.single .entry-info {
  position: relative;
}

.single .entry-info > div {
  margin-bottom: 20px;
}

.single .entry-info div:last-child {
  margin-bottom: 0;
}

.single .entry-info .cat-links {
  margin-top: 5px;
}

.single .entry-info:after {
  content: "";
  width: 5px;
  height: 100%;
  position: absolute;
  right: -20px;
  top: -5px;
  background-color: #2b7a0b;
}

.single .post-info-wrapper .text-holder {
  font-size: 10px;
  color: #000;
  letter-spacing: 2px;
  margin-bottom: 0;
  line-height: 15px;
}

.single article {
  padding-top: 75px;
}

.single h1.entry-title {
  font-weight: 700;
  font-size: 48px;
  line-height: 115%;
  margin-bottom: 10px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  text-align: center;
  width: 720px;
  margin: 0 auto 30px;
  max-width: 95%;
  color: #000;
  padding-top: 0;
}

.single .entry-info div:last-of-type:after {
  display: none;
}

.single .entry-info ul {
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 150%;
}

.single .entry-info ul li {
  display: inline-block;
}

.single .entry-info .cat-links li:after {
  content: ",";
  padding-right: 5px;
}

.single .entry-info .cat-links ul li:last-child:after {
  display: none;
}

.single-post-featured-image {
  text-align: center;
  padding-bottom: 40px;
  width: 1300px;
  margin-left: auto;
  margin-right: auto;
  max-width: 95%;
}

.single .entry-content {
  font-size: 15px;
  line-height: 30px;
  color: #000;
  width: 80%;
  margin: auto;
  margin-bottom: 5rem;
}

.single .nav-links {
  transform: translateY(70px);
  margin-top: 35px;
  margin-bottom: 50px;
}

.single .nav-links > a {
  background-color: #2b7a0b;
  padding: 26px 30px 24px;
  margin: 0;
  display: inline;
  color: #fff !important;
  transition: 0.3s;
}

.single .nav-links > a:hover {
  background-color: #73f473;
}

/* ===================================
   14.1 Tipper CSS
====================================== */

.tipper {
  width: 100%;
  min-height: 1px;
  position: absolute;
  top: -999px;
  left: -999px;
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s linear;
  max-width: 500px;
}

.tipper-visible {
  opacity: 1;
}

.tipper-content {
  background: #111;
  border-radius: 3px;
  color: #fff;
  display: block;
  float: left;
  font-size: 22px;
  margin: 0;
  padding: 10px 15px;
  position: relative;
  line-height: 28px;
  font-weight: 700;
  text-align: center;
}

.tipper-caret {
  width: 0;
  height: 0;
  content: "";
  display: block;
  margin: 0;
  position: absolute;
}

.tipper-left .tipper-content {
  box-shadow: -1px 0 5px rgba(0, 0, 0, 0.35);
}

/* ===================================
    15. Contact Section CSS
====================================== */

.contact-form {
  max-width: 100%;
  margin: 0 auto;
  font-size: 22px;
  color: #000;
}

.contact-form p {
  margin-bottom: 0 !important;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  border: 0;
  font-size: 15px;
  padding: 5px 0;
  width: 100%;
  line-height: 25px;
  color: #000;
  margin-bottom: 35px;
  text-indent: 10px;
  background-color: transparent;
  border-bottom: 3px solid;
}

.contact-form textarea {
  height: 150px;
}

.contact-form input[type="text"]::placeholder,
.contact-form input[type="email"]::placeholder,
.contact-form textarea::placeholder {
  font-family: "Rubik", sans-serif;
  color: #000;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  opacity: 1;
}

.contact-form input[type="text"]:ms-input-placeholder,
.contact-form input[type="email"]:ms-input-placeholder,
.contact-form textarea:ms-input-placeholder {
  font-family: "Rubik", sans-serif;
  color: #000;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
}

.contact-form input[type="text"]::ms-input-placeholder,
.contact-form input[type="email"]::ms-input-placeholder,
.contact-form textarea::ms-input-placeholder {
  font-family: "Rubik", sans-serif;
  color: #000;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
}

.contact-form input[name="your-name"] {
  margin-top: 0;
}

.contact-submit-holder {
  position: relative;
  margin-bottom: 25px;
}

.contact-form input[type="submit"] {
  color: #fff;
  background-color: #2b7a0b;
  text-align: center;
  padding: 10px 40px;
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none;
  transition: all 0.2s linear;
  margin-bottom: 17px;
  display: block;
  width: 100%;
  line-height: 35px;
  border: 2px solid #2b7a0b;
  border-radius: 50px;
  font-size: 14px;
  letter-spacing: 2px;
  box-sizing: border-box;
}

.contact-form input[type="submit"]:hover {
  background-color: #fff;
  color: #2b7a0b;
}

/* ===================================
    16. Footer CSS
====================================== */

footer a {
  color: #fff;
}

footer ul {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}

.footer {
  font-size: 16px;
  line-height: 36px;
  padding-top: 75px;
  padding-bottom: 60px;
  clear: both;
  color: #fff;
  text-align: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  background-color: #111;
}

.footer-content {
  width: 900px;
}
.footer-logo.achievements {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media screen and (max-width: 1024px) {
  .footer-logo.achievements {
    margin-top: 2rem;
    flex-direction: column;
  }
}

.footer-logo img {
  height: 150px;
  margin-bottom: 30px;
  border-radius: 15px;
}

.footer-logo-divider {
  position: relative;
  height: 60px;
  margin-bottom: 30px;
}

.footer-logo-divider:before {
  content: "";
  width: 2px;
  height: 60px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: calc(50% - 1px);
}

.footer-mail {
  font-size: 46px;
  margin-bottom: 35px;
  line-height: 100%;
  font-weight: 700;
}

.footer-social-divider {
  position: relative;
  height: 62px;
  margin-bottom: 35px;
}

footer .footer-social-divider:after {
  content: "";
  position: absolute;
  width: 300px;
  height: 2px;
  background-color: white;
  left: calc(50% - 150px);
  bottom: 0;
}

footer .footer-social-divider:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 60px;
  background-color: white;
  left: calc(50% - 1px);
  top: 0;
}

.social-holder {
  margin-bottom: 35px;
}

.social-holder a {
  font-size: 28px;
  margin-right: 25px;
}

.social-holder a:last-of-type {
  margin: 0;
}

/* ===================================
    17. Responsive CSS
====================================== */

@media screen and (max-width: 1600px) {
  .content-1170,
  .content-960,
  .content-570 {
    max-width: 95%;
  }
}

@media screen and (max-width: 1366px) {
  .blog-holder-scode {
    width: 100%;
    transform: none;
  }

  .blog-item-holder-scode {
    width: 45%;
    /* margin-right: 5%; */
  }

  .blog-item-holder-scode h4 a {
    font-size: 32px;
  }
}

@media screen and (max-width: 1360px) {
  .blog-item-holder.has-post-thumbnail .entry-holder {
    width: 340px;
  }
}

@media screen and (max-width: 1270px) {
  .member-info {
    padding: 65px 80px;
  }
}

@media screen and (max-width: 1370px) {
  .toggle-holder {
    display: block;
  }
  .sm,
  .sm ul,
  .sm li {
    width: 100%;
  }

  .menu-holder {
    position: fixed;
    text-align: center;
    background-color: #000000;
    right: -100%;
    top: 77px;
    transition: right 0.3s ease;
    height: 100%;
    width: 300px;
    overflow: auto;
    max-width: 100%;
  }

  .header-holder {
    -webkit-transform: none;
  }

  .menu-holder.show {
    right: 0;
  }
}
@media screen and (max-width: 1220px) {
  blockquote {
    font-size: 17px;
    line-height: 28px;
  }
}

@media screen and (max-width: 1024px) {
  .sm-clean li {
    margin-right: 8px;
  }

  .sm-clean a,
  .sm-clean a:hover,
  .sm-clean a:focus,
  .sm-clean a:active,
  .sm-clean a.highlighted {
    padding: 15px 8px;
  }

  .blog-holder,
  .footer-content {
    width: 100%;
  }

  .single .post-info-wrapper {
    width: 100%;
    text-align: center;
    float: none;
  }

  .single .sticky-spacer {
    width: 100% !important;
    height: auto !important;
    max-width: 100%;
  }

  .single .entry-info {
    position: relative !important;
    top: 0 !important;
  }

  .single .entry-info:after {
    display: none;
  }

  .single .sticky-spacer .entry-info > div {
    display: inline-block;
    margin: 10px 25px;
  }

  .single .entry-content {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
  }

  .one_half,
  .one_third,
  .two_third,
  .one_fourth,
  .three_fourth {
    margin-right: 6%;
  }

  .one_half {
    width: 47%;
  }

  .one_third {
    width: calc(88% / 3);
  }

  .two_third {
    width: calc(194% / 3);
  }

  .one_fourth {
    width: calc(82% / 4);
  }

  .three_fourth {
    width: calc(318% / 4);
  }

  .section h3.entry-title {
    margin-bottom: 0 !important;
  }

  .sticky-spacer {
    height: auto !important;
    width: auto !important;
    position: relative !important;
  }

  .blog-item-holder-scode {
    width: 45%;
  }

  img.latest-posts-background-featured-image-holder {
    height: 350px;
  }

  .pricing-wrapper {
    padding: 0 10%;
  }

  li.milestone {
    text-align: center;
  }

  li.milestone .milestone-info-left {
    margin: 0 auto;
    display: block;
    width: 100%;
    text-align: center;
  }

  li.milestone h5 {
    max-width: 100%;
  }
}

@media screen and (max-width: 1020px) {
  #header-main-menu {
    padding-bottom: 70px;
  }

  .menu-wrapper {
    width: 95%;
  }

  .main-menu ul {
    width: 12em;
  }

  .toggle-holder {
    display: block;
  }

  .sm-clean li {
    margin: 0 auto !important;
    border: 0;
  }

  .sm-clean a.has-submenu {
    display: inline-block;
  }

  .menu-holder {
    position: fixed;
    text-align: center;
    background-color: #000000;
    right: -100%;
    top: 77px;
    transition: right 0.3s ease;
    height: 100%;
    width: 300px;
    overflow: auto;
    max-width: 100%;
  }

  .header-holder {
    -webkit-transform: none;
  }

  .menu-holder.show {
    right: 0;
  }

  .sm-clean ul ul a,
  .sm-clean ul ul a:hover,
  .sm-clean ul ul a:focus,
  .sm-clean ul ul a:active {
    border: none;
  }

  .sm-clean a span.sub-arrow {
    background: transparent;
  }

  .sm-clean a,
  .sm-clean a:hover,
  .sm-clean a:focus,
  .sm-clean a:active,
  .sm-clean a.highlighted {
    padding: 10px 18px 10px 18px;
    padding-left: 10px !important;
    margin-bottom: 5px;
  }

  h1.big-text {
    text-align: center;
    font-size: 60px;
  }

  h4.big-text {
    text-align: center;
  }

  .section-wrapper {
    padding-bottom: 70px;
  }

  .single article {
    padding-top: 35px;
  }

  .single h1.entry-title {
    font-size: 38px;
    line-height: 44px;
  }

  .single-post-header-content {
    margin-bottom: 35px;
  }

  .single-post .right-content-wrapper {
    float: none;
    width: 100%;
  }

  .tipper {
    display: none !important;
  }

  .blog-item-holder {
    width: 100%;
    padding: 5px 0;
    float: none;
  }

  .blog-item-wrapper {
    display: block;
  }

  .blog-item-holder:first-of-type {
    margin-top: 30px;
  }

  .blog-item-holder h2.entry-title {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .blog-item-holder.has-post-thumbnail .excerpt {
    max-width: 700px;
    margin-bottom: 30px;
  }

  .blog-item-holder .post-thumbnail {
    float: none !important;
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 30px;
    width: 95%;
    margin-top: 0;
  }

  .blog-item-holder.has-post-thumbnail .entry-holder {
    float: none !important;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 100%;
    padding: 0;
  }

  .blog-item-holder .cat-links ul {
    margin: 0;
  }

  .blog-item-holder a.button {
    margin-bottom: 30px;
  }

  .footer {
    padding-top: 30px;
  }

  .form-submit {
    padding-bottom: 20px;
  }

  .one_half,
  .one_third,
  .one_fourth,
  .two_third,
  .three_fourth {
    width: 100% !important;
    float: none;
    margin-right: auto !important;
    margin-left: auto !important;
    text-align: center;
  }

  .margin-0 img {
    width: 100% !important;
  }

  #portfolio-grid {
    text-align: center;
    margin: auto;
  }

  .grid-item {
    width: 50% !important;
    margin: auto;
  }
  .grid-sizer {
    width: 100% !important;
  }

  .button-holder {
    text-align: center !important;
  }

  .service-holder {
    text-align: center;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .service-holder.values {
    text-align: center;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  [data-jarallax-element] {
    transform: none !important;
  }

  .no-page-title .section-wrapper {
    padding: 65px 0 35px 0;
  }

  .text-slider-wrapper {
    padding: 10%;
  }

  .blog-item-holder-scode {
    display: block;
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto !important;
    max-width: 400px;
  }

  .medium-text {
    font-size: 40px;
    line-height: 105%;
    padding-top: 10px;
  }

  .portfolio-load-content-holder {
    max-width: 90%;
  }

  .close-icon {
    top: 120px;
    right: -10px;
    left: auto;
  }

  .pricing-table {
    margin-bottom: 100px;
  }

  .member {
    margin: 35px auto;
    max-width: 80%;
  }

  .member img {
    display: block;
    margin: 0 auto;
    width: 100% !important;
  }

  .member-info {
    display: block;
    margin: 0 auto !important;
    width: 80%;
    padding: 10%;
  }

  .member-social-holder {
    right: 0 !important;
    left: 0 !important;
    width: 100%;
    text-align: center;
    padding: 25px 0;
  }

  .skill-percent {
    display: block;
    width: 100%;
    font-size: 40px;
  }

  .skill-text {
    display: block;
    width: 100%;
  }

  .skill-text span {
    margin-bottom: 10px;
    display: block;
  }

  li.milestone {
    width: 100%;
    margin-bottom: 60px;
    display: block;
    max-width: 350px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  li.milestone:last-child {
    margin-bottom: 0;
  }

  li.milestone > div {
    width: 100% !important;
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center !important;
  }

  p.milestone-num {
    font-size: 65px;
  }

  li.milestone h5 {
    max-width: 100%;
    margin-top: 20px;
  }

  .portfolio-item-wrapper {
    margin-top: 50px;
  }

  .single .nav-links {
    transform: none;
    margin-top: 50px;
    text-align: center;
  }

  .padding-1020 {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .page-title-holder {
    height: 100px;
  }

  .page-title-holder-left {
    height: 100px;
  }

  .section h3.entry-title {
    margin-top: 18px;
  }

  .service-holder {
    margin: 0 auto;
  }

  .section-wrapper {
    padding: 130px 0 30px 0;
  }

  .page-title-holder:after {
    border-width: 100px 60px 0 0;
  }
  .page-title-holder-left:before {
    border-width: 0 0 100px 60px;
  }

  .blog-item-holder h2.entry-title {
    font-size: 34px;
    line-height: 38px;
  }

  .footer-mail {
    font-size: 30px;
  }

  blockquote {
    max-width: 100%;
    margin-top: 25px;
  }

  blockquote:before {
    position: relative;
    left: 0;
    font-size: 180px;
    line-height: 37px;
    top: 0;
  }

  .blog-item-holder-scode .post-thumbnail {
    margin-top: 70px;
  }

  .blog-holder-scode > article:first-of-type .post-thumbnail {
    margin-top: 0;
  }

  img.latest-posts-background-featured-image-holder {
    height: 300px;
  }

  .member {
    max-width: 100%;
  }

  .member-name {
    font-size: 35px;
  }

  .member-content {
    font-size: 16px;
    line-height: 170%;
    margin-bottom: 50px;
  }

  .member-postition {
    font-size: 13px;
  }

  .text-slider-wrapper {
    width: 100%;
  }

  .text-slider-wrapper .text-slide {
    font-size: 20px;
  }

  .text-slider-wrapper img.text-slide-img {
    display: block;
    margin-bottom: 10px;
  }

  .text-slider-wrapper .text-slide-name {
    display: block;
    margin-bottom: 5px;
  }

  .text-slider-wrapper .text-slide-position {
    display: block;
    font-size: 14px;
    line-height: 125%;
  }
}

@media screen and (max-width: 750px) {
  .blog-item-holder .entry-holder {
    max-width: 95%;
  }
}

@media screen and (max-width: 650px) {
  .blog-item-holder .entry-date.published,
  .blog-item-holder .cat-links,
  .single .entry-info div,
  .single .entry-info .cat-links ul {
    display: block;
  }

  .blog-item-holder .entry-date.published:after,
  .single .entry-info div:after {
    display: none;
  }

  h1.big-text {
    text-align: center;
    font-size: 40px;
  }
  h4.big-text {
    text-align: center;
  }

  #home h3.big-text {
    font-size: 24px;
    padding-top: 0;
    padding-left: 5rem;
    padding-right: 5rem;
  }

  #home {
    background-image: url(images/hero4.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
  }

  #filler-div {
    height: 300px;
  }
  /* 
  .display-button {
    display: none;
  } */
}

@media screen and (max-width: 970px) {
  #filler-div {
    height: 200px;
  }

  #home h1.big-text {
    padding-bottom: 0;
    font-size: 40px;
  }
  #home h3.big-text {
    padding-left: 7px;
    padding-right: 7px;
    padding-bottom: 0;
    font-size: 24px;
  }
  /* .display-button {
    display: none;
  } */
}

@media screen and (max-width: 400px) {
  .page-title-holder {
    width: 200px;
  }

  .page-title-holder-left {
    width: 200px;
  }

  .footer-mail {
    font-size: 24px;
  }
}

@media screen and (max-width: 350px) {
  .footer-mail {
    font-size: 18px;
  }
}

.galleryClass {
  max-height: 300px;
}

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.text-center {
  text-align: center;
}

.make-green {
  color: #2b7a0b;
}

.make-smaller {
  font: 0.75rem;
}

.flex-custom-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-custom-container img {
  border-radius: 15px;
}

.flex-custom-container.champions {
  margin-right: 3rem;
  margin-left: 3rem;
  margin-bottom: 2rem;
  margin-top: -3rem;
}

.testimonial-margin {
  padding-top: 5rem;
}

.flex-custom-container img {
  max-width: 300px;
  max-height: 300px;
  height: auto;
  width: auto;
  margin-bottom: 20px;
}

.flex-custom-container .poodle-sign {
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: center;
  align-content: center;
  justify-content: center;
  justify-content: center;
}

.flex-custom-container .poodle-sign img {
  width: 97%;
}

img.champions-images {
  max-width: 400px;
  /* max-height: 400px; */
  height: auto;
  width: auto;
  margin-bottom: 30px;
  border-radius: 15px;
  object-fit: cover;
}

.pagination-container {
  display: flex;
  justify-content: center;
}
.pagination {
  text-align: center;
  margin: auto;
}

.pagination div {
  min-width: 2rem;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 500;
}
.pagination div:hover {
  background-color: white;
  color: black;
  cursor: pointer;
}

.pagination div.active {
  background-color: white;
  color: black;
}

.pagination div.first {
  border-left: 1px solid white;
}

img.testimonial-images {
  max-height: 500px;
  max-width: 500px;
  height: auto;
  width: auto;
  margin-bottom: 30px;
  border-radius: 15px;
  object-fit: cover;
}
.image {
  border-radius: 15px;
}

.champions-container {
  display: flex;
  justify-items: center;
  justify-content: space-evenly;
}

.champions-buttons-container {
  background-color: #000;
  padding-top: 10rem;
  display: flex;
  justify-content: space-evenly;
  justify-items: center;
  flex-wrap: wrap;
}

.sticky-scroll-button {
  position: fixed;
  right: 20px;
  bottom: 50px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: #2b7a0b;
}

.sticky-scroll-button.bottom {
  bottom: 100px;
}

.years-pagination {
  text-align: center;
}

.pagination-prev-next {
  display: inline;
}

.home-section-image {
  height: 75%;
}

/* Image Gallery */

.image-gallery-icon {
  color: #fff;
  transition: all 0.3s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 2px 2px #1a1a1a);
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-icon:hover {
    color: #2b7a0b;
  }
  .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1.1);
  }
}
.image-gallery-icon:focus {
  outline: 2px solid #2b7a0b;
}
.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none;
}
.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px;
}
.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 28px;
  width: 28px;
}
@media (max-width: 768px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 15px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 24px;
    width: 24px;
  }
}
@media (max-width: 480px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 10px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 16px;
    width: 16px;
  }
}
.image-gallery-fullscreen-button {
  right: 0;
}
.image-gallery-play-button {
  left: 0;
}
.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 60px;
}
@media (max-width: 768px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 72px;
    width: 36px;
  }
}
@media (max-width: 480px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 48px;
    width: 24px;
  }
}
.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
  cursor: disabled;
  opacity: 0.6;
  pointer-events: none;
}
.image-gallery-left-nav {
  left: 0;
}
.image-gallery-right-nav {
  right: 0;
}
.image-gallery {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
}
.image-gallery.fullscreen-modal {
  background: #000;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
}
.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;
}
.image-gallery-content.fullscreen {
  background: #000;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(75vh - 80px);
}
.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}
.image-gallery-slide-wrapper {
  position: relative;
}
.image-gallery-slide-wrapper.left,
.image-gallery-slide-wrapper.right {
  display: inline-block;
  width: calc(100% - 110px);
}
@media (max-width: 768px) {
  .image-gallery-slide-wrapper.left,
  .image-gallery-slide-wrapper.right {
    width: calc(100% - 87px);
  }
}
.image-gallery-slide-wrapper.image-gallery-rtl {
  direction: rtl;
}
.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center;
}
.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.image-gallery-slide.center {
  position: relative;
}
.image-gallery-slide .image-gallery-image {
  padding-top: 40px;
  width: 100%;
  object-fit: contain;
}
.image-gallery-slide .image-gallery-description {
  color: #fff;
  top: 0;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
}
@media (max-width: 768px) {
  .image-gallery-slide .image-gallery-description {
    bottom: 45px;
    font-size: 0.8em;
    padding: 8px 15px;
  }
}
.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;
}
.image-gallery-bullets .image-gallery-bullets-container {
  margin: 0;
  padding: 0;
  text-align: center;
}
.image-gallery-bullets .image-gallery-bullet {
  appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  box-shadow: 0 2px 2px #1a1a1a;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  outline: none;
  padding: 5px;
  transition: all 0.2s ease-out;
}
@media (max-width: 768px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
    padding: 3px;
  }
}
@media (max-width: 480px) {
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2.7px;
  }
}
.image-gallery-bullets .image-gallery-bullet:focus {
  transform: scale(1.2);
  background: #2b7a0b;
  border: 1px solid #2b7a0b;
}
.image-gallery-bullets .image-gallery-bullet.active {
  transform: scale(1.2);
  border: 1px solid #fff;
  background: #fff;
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-bullets .image-gallery-bullet:hover {
    background: #2b7a0b;
    border: 1px solid #2b7a0b;
  }
  .image-gallery-bullets .image-gallery-bullet.active:hover {
    background: #2b7a0b;
  }
}
.image-gallery-thumbnails-wrapper {
  position: relative;
}
.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
  touch-action: pan-y;
}
.image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical {
  touch-action: pan-x;
}
.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
  direction: rtl;
}
.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  display: inline-block;
  vertical-align: top;
  width: 100px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    width: 81px;
  }
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
  height: 100%;
  width: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
}
.image-gallery-thumbnails-wrapper.left
  .image-gallery-thumbnails
  .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right
  .image-gallery-thumbnails
  .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
}
.image-gallery-thumbnails-wrapper.left
  .image-gallery-thumbnails
  .image-gallery-thumbnail
  + .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right
  .image-gallery-thumbnails
  .image-gallery-thumbnail
  + .image-gallery-thumbnail {
  margin-left: 0;
  margin-top: 2px;
}
.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  margin: 0 5px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    margin: 0 3px;
  }
}
.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails {
    padding: 3px 0;
  }
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}
.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border 0.3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail {
    border: 3px solid transparent;
    width: 81px;
  }
}
.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 2px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  display: block;
  position: relative;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #2b7a0b;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:focus {
    border: 3px solid #2b7a0b;
  }
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-thumbnail:hover {
    outline: none;
    border: 4px solid #2b7a0b;
  }
}
@media (hover: hover) and (pointer: fine) and (max-width: 768px) {
  .image-gallery-thumbnail:hover {
    border: 3px solid #2b7a0b;
  }
}
.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: #fff;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 0 2px 2px #1a1a1a;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail-label {
    font-size: 0.8em;
    line-height: 0.8em;
  }
}
.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}
@media (max-width: 768px) {
  .image-gallery-index {
    font-size: 0.8em;
    padding: 5px 10px;
  }
}

.about-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.site-wrapper img.values-image {
  width: 60%;
  border-radius: 15px;
}

@media screen and (max-width: 1200px) {
  #home {
    background-image: url(images/hero4.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
  }
}

@media screen and (max-width: 1024px) {
  .about-wrapper {
    flex-direction: column;
  }
  .about-wrapper .one_half.last img {
    max-width: 70%;
  }
}

@media screen and (max-width: 767px) {
  .site-wrapper img.values-image {
    width: 80%;
    border-radius: 15px;
  }
}

@media screen and (max-width: 450px) {
  .site-wrapper img.values-image {
    width: 100%;
    border-radius: 15px;
  }
}

@media (max-width: 750px) {
  .flex-custom-container .poodle-sign img {
    width: 85%;
  }
}

@media (max-width: 480px) {
  img.champions-images {
    max-width: 75%;

    height: auto;
    width: auto;
    margin-bottom: 30px;
    border-radius: 15px;
    object-fit: cover;
  }

  .flex-custom-container img {
    max-width: 75%;
    height: auto;
    width: auto;
    margin-bottom: 20px;
  }
}

.thumbs.animated {
  margin: auto;
  text-align: center;
}

.thumbs {
  margin: auto;
  text-align: center;
}

.champions-carousel .carousel button.control-prev.control-arrow:before {
  border-right: 8px solid #000;
}

.champions-carousel .carousel button.control-next.control-arrow:before {
  border-left: 8px solid #000;
}
